.str-chat__main-panel {
  position: relative;
  .rfu-dropzone {
    .rta {
      display: flex;
      justify-content: center;
      &.str-chat__textarea > textarea {
        padding: 34px;
        padding-inline: 60px;
        margin-inline: 24px;
        font-size: 13px;
        letter-spacing: 0.4px;
        line-height: 16px;
      }
    }
  }
  .rfu-file-previewer__close-button,
  .rfu-icon-button svg {
    z-index: 0;
  }

  .rfu-file-upload-button {
    position: absolute;
    top: 40%;
    left: 48px;
  }
  .str-chat__send-button {
    display: block;
    cursor: pointer;
    position: absolute;
    top: 40%;
    right: 54px;
    outline: none;
    margin-left: 20px;
  }
}
