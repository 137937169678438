.str-chat-channel-list {
  .str-chat__channel-list-messenger__main {
    padding: 0;
  }
}

.str-chat-channel.messaging .str-chat__main-panel {
  padding: 0;
  height: 88%;
}

//Search field

.str-chat__channel-search {
  background-color: transparent;
  padding: 22px 16px;
  border-radius: 0px;
  display: flex;
  justify-content: center;
  input {
    background-color: transparent;
    border-radius: 4px;
    height: 56px;
    width: 328px;
    border: 1px solid rgba(0, 0, 0, 0.12);
    &:focus {
      box-shadow: #a3c7f6 0px 0px 0px 3px;
      border: none;
    }
  }
}

//Message boxes
.str-chat__message-text {
  position: relative;
  .str-chat__message-text-inner {
    border-radius: 4px !important;
    // width: 443px;
    // height: 93px;
    padding: 16px;
    font-size: 15px;
    letter-spacing: 0.25px;
    line-height: 20px;
  }
}
.str-chat__message--me {
  .str-chat__message-text-inner {
    background-color: #e8f1fd;
  }
}

// .str-chat__message-data,
// .str-chat__message-simple-data {
//   position: absolute;
//   top: 35%;
//   right: -36px !important;
// }

.str-chat__message-simple {
  align-items: baseline;
}
